import "./TelaRecoverPassword.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getAuth, updatePassword, updateProfile } from "firebase/auth";
import db from "./firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";

function AtualizarSenha() {
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState("");
  const [senhaRepedida, setSenhaRepedida] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const usersRef = collection(db, "users");
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    updatePassword(user, senha)
      .then(() => {
        // Update successful.
        setSuccess("Senha atualizada com sucesso!");
        setDoc(doc(usersRef, user.email), {
          email: user.email,
          uid: user.uid,
          passwordDefined: true,
        });
      })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((error) => {
        setError("Um erro ocorreu ao tentar atualizar senha.");
      });
  };

  return (
    <div className="telaRecoverPassword__container">
      <div className="telaRecoverPassword">
        <div className="telaRecoverPassword__header">
          <span className="telaRecoverPassword__header_title">
            Definir Senha
          </span>
          <span className="telaRecoverPassword__header_subtitle">
            Defina uma nova senha para acessar o robô.
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="telaRecoverPassword__inputsContainer">
            <input
              className="telaRecoverPassword__inputsContainer_input"
              label="Digite uma nova senha"
              placeholder="Digite uma nova senha"
              value={senha}
              type="password"
              onChange={(e) => setSenha(e.target.value)}
            />
            <input
              className="telaRecoverPassword__inputsContainer_input"
              label="Digite a senha novamente"
              placeholder="Digite a senha novamente"
              value={senhaRepedida}
              type="password"
              onChange={(e) => {
                setSenhaRepedida(e.target.value);
                if (e.target.value !== senha) {
                  setError("As senhas não coincidem.");
                } else {
                  setError("");
                }
              }}
            />
          </div>
          {success && (
            <span className="telaRecoverPassword__success">{success}</span>
          )}
          {error && <span className="telaRecoverPassword__error">{error}</span>}

          <span onClick={handleSubmit} className="telaRecoverPassword__button">
            Salvar
            {loading && <CircularProgress />}
          </span>
        </form>
      </div>
      <div className="telaRecoverPassword__textoLinkBottom">
        <span className="telaRecoverPassword__span">
          Quer tentar fazer login novamente?
        </span>
        <Link to="/login">
          <span className="telaRecoverPassword__linkBottom"> Clique aqui</span>
        </Link>
      </div>
    </div>
  );
}

export default AtualizarSenha;
