import './FridaySectionCard.css';

function FridaySectionCard({ img, title, text }) {
    return (
        <div className="fridaySectionCard">
            <img className='fridaySectionCard__img' src={img} />
            <div className='fridaySectionCard__textContainer'>
                <span className='fridaySectionCard__textContainer_title'>{title}</span>
                <span className='fridaySectionCard__textContainer_text'>{text}</span>
            </div>
        </div>
    );
}

export default FridaySectionCard;