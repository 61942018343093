import "./NewPlans.css";
import NewCard from "./NewCard";
import { basico, profissional, automatizado } from "./NewCardData";

function NewPlans({ signIn }) {
  return (
    <div className="newPlans">
      <h1>ASSINE AGORA</h1>
      <div className="newPlans__cardsContainer">
        <NewCard
          rows={basico}
          type="Básico"
          price={"49"}
          virgula={",90"}
          signIn={signIn}
        />
        <NewCard
          rows={profissional}
          type="Profissional"
          price={"69"}
          virgula={",90"}
          signIn={signIn}
        />
        <NewCard
          rows={automatizado}
          type="Automatizado"
          price={"89"}
          virgula={",90"}
          signIn={signIn}
        />
      </div>
    </div>
  );
}

export default NewPlans;
