import { createContext, useState } from "react";
export const ContextSettings = createContext();

export const ContextSettingsProvider = ({ children }) => {
  const [data, setData] = useState({});

  return (
    <ContextSettings.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </ContextSettings.Provider>
  );
};
