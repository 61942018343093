import React from "react";
import "./Header.css";

function Header({ signIn, loading }) {
  return (
    <div className="header">
      <div></div>
      <div className="header__logoContainer">
        <h1 className="header__logo header__logoO">Automatiza</h1>
        <h1 className="header__logo">Trading</h1>
      </div>
      {/* <img src="OGERENCIAMENTO.png" /> */}

      <div className="header__right">
        <div onClick={signIn} className="header__signIn">
          {loading ? "CARREGANDO..." : "ENTRAR"}
        </div>
        <div onClick={signIn} className="header__signUp">
          {loading ? "CARREGANDO..." : "CADASTRAR"}
        </div>
      </div>
    </div>
  );
}

export default Header;
