import "./App.css";
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TelaLogin from "./TelaLogin";
import TelaRecoverPassword from "./TelaRecoverPassword";
import TelaCadastro from "./TelaCadastro";
import TelaObrigado from "./TelaObrigado";
import { useContext, useEffect } from "react";
import { ContextSettings } from "./ContextSettings";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import TelaEmailRecuperacao from "./TelaEmailRecuperacao";
import Site from "./Site";
import AtualizarSenha from "./AtualizarSenha";

function App() {
  const { data, setData } = useContext(ContextSettings);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (authUser) => {
      setData({ ...data, user: authUser });
    });
  }, []);

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/atualizar-senha">
            <AtualizarSenha />
          </Route>
          <Route path="/cadastro">
            <TelaCadastro />
          </Route>

          <Route path="/obrigado">
            <TelaObrigado />
          </Route>

          <Route path="/email-de-recuperacao">
            <TelaEmailRecuperacao />
          </Route>

          <Route path="/recuperar-senha">
            <TelaRecoverPassword />
          </Route>

          <Route path="/login">
            <TelaLogin />
          </Route>

          <Route path="/home">
            <Home />
          </Route>

          <Route path="/">
            <Site />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
