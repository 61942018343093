import React from "react";
import "./Card.css";

function Card({
  cycle,
  price,
  cents,
  popular,
  saves,
  description,
  loadCheckout,
  isLoading,
  priceMask,
  priceId,
}) {
  return (
    <div className={`card ${popular && "card__popular"}`}>
      <div className="card__header">
        <span className="card__headerPopular">Mais Popular</span>
        <span className="card__headerCycle">{cycle}</span>
        <span className="card__headerSaves">{saves}</span>
      </div>
      <div className="card__body">
        <div className="card__bodyPriceContainer">
          <span className="card__cifrao">R$</span>
          <span className="card__bodyPrice">{price}</span>
          <span className="card__bodyCents">{cents}</span>
          <span className="card__mes">/mês</span>
        </div>
        <span className="card__bodyDescription">{description}</span>
      </div>
      <div className="card__footer">
        <span onClick={loadCheckout} className="card__footerButton">
          {isLoading[priceMask[priceId]] ? "Aguarde..." : "Assinar"}
        </span>
      </div>
    </div>
  );
}

export default Card;
