import React, { useContext, useRef, useState } from "react";
import "./Home.css";
import { getAuth, signOut } from "firebase/auth";
import { ContextSettings } from "./ContextSettings";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import db from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import Card from "./Card";
import axios from "axios";
import { EmailOutlined } from "@material-ui/icons";
const PROXY = "https://stormy-peak-12286.herokuapp.com/";
let first_time = true;

const sandbox = true;

const sandbox_client_id = "54cff339-6a7a-4bbc-b69a-005e9247e098";
const sandbox_client_secret = "3f27d6b9-ee37-4f43-8b62-5c433d06bbdd";
const sandbox_basic =
  "Basic NTRjZmYzMzktNmE3YS00YmJjLWI2OWEtMDA1ZTkyNDdlMDk4OjNmMjdkNmI5LWVlMzctNGY0My04YjYyLTVjNDMzZDA2YmJkZA==";

const client_id = "2e57299b-b7c1-4349-a215-99e9bc4ece55";
const client_secret = "d33b54c8-e2ca-4554-b80b-7bff7ffe90c4";
const basic =
  "Basic MmU1NzI5OWItYjdjMS00MzQ5LWEyMTUtOTllOWJjNGVjZTU1OmQzM2I1NGM4LWUyY2EtNDU1NC1iODBiLTdiZmY3ZmZlOTBjNA==";

function Home() {
  const { data, setData } = useContext(ContextSettings);
  const { user } = data;
  const [active, setActive] = useState(false);
  const [overdue, setOverdue] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [accessToken, setAccesToken] = useState(null);
  const [email, setEmail] = useState("teste.sandbox4@hotmart.com");
  const auth = getAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState({
    Mensal: false,
    Semestral: false,
    Anual: false,
  });

  const getTokenAccess = () => {
    axios
      .post(
        `${PROXY}https://api-sec-vlc.hotmart.com/security/oauth/token?grant_type=client_credentials&client_id=${
          sandbox ? sandbox_client_id : client_id
        }&client_secret=${sandbox ? sandbox_client_secret : client_secret}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sandbox ? sandbox_basic : basic,
          },
        }
      )
      .then((response) => {
        const { access_token } = response.data;
        setAccesToken(access_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getIsActive = (next_page = null) => {
    if (accessToken == null) return;
    const ifNextPage = next_page ? `&page_token=${next_page}` : "";
    axios
      .get(
        `${PROXY}https://${
          sandbox ? "sandbox" : "developers"
        }.hotmart.com/payments/api/v1/subscriptions?status=CANCELLED_BY_SELLER&status=ACTIVE${ifNextPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        const { next_page_token } = response.data.page_info;
        if (next_page_token) {
          setNextPage(next_page_token);
        }
        const { items } = response.data;
        items.map((item) => {
          let currentEmail = item.subscriber.email;
          const compare_email = sandbox
            ? "teste.sandbox4@hotmart.com"
            : user.email;
          if (currentEmail === compare_email) {
            let { status } = item;
            if (status === "ACTIVE") {
              setActive(true);
            }

            if (status === "OVERDUE") {
              setOverdue(true);
            }
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (first_time) {
      getTokenAccess();
      if (accessToken) first_time = false;
    } else {
      if (user?.uid) {
        getIsActive(nextPage);
      }
    }
  }, [nextPage, accessToken]);

  const priceMask = {
    price_1Lg7x5DaEHvZEwLqMP5DcJ7q: "Mensal",
    price_1Lg7x5DaEHvZEwLqXa1Ips3b: "Semestral",
    price_1Lg7yXDaEHvZEwLqYXjJbRfr: "Anual",
  };

  const goToCustomerPortal = () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://purchase.hotmart.com/purchase/detail/2380536";
    a.click();
  };

  const loadCheckout = (url) => {
    alert(
      'Certifique-se de realizar a compra com o mesmo "gmail" que está logado no Automatiza Trading.'
    );
    let a = document.createElement("a");
    // a.target = "_blank";
    a.href = `${url}&email=${user?.email}`;
    a.click();
  };

  useEffect(() => {
    if (data.user) {
      setEmail(user?.email);
    }
  }, [data]);

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
  }, [data]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        setData({ ...data, user: null });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div className="home">
      <div className="home__container">
        <div className="home__esquerda">
          <img src="AT.png" className="home__esquerda_logo" />
          <span className="home__esquerda_span">Automatiza Trading</span>
        </div>

        <div className="home__direita">
          <span className="home__direita_nome">{user?.displayName}</span>
          <span onClick={handleSignOut} className="home__direita_botaoSair">
            {" "}
            Sair
          </span>
        </div>
      </div>

      <div className="home__card">
        <div className="home__cardUserInfo">
          <span className="home__cardSubtitle">
            Nome:{" "}
            <span className="home__cardDescription">{user?.displayName}</span>
          </span>
          <span className="home__cardSubtitle">
            Email: <span className="home__cardDescription">{user?.email}</span>
          </span>
          {/* <span className="home__cardSubtitle">
            Situação da Assinatura:{" "}
            <span
              className="home__cardDescription"
              style={{ color: active ? "green" : "red" }}
            >
              {active
                ? "Ativa"
                : overdue
                ? "Pagamento Atrasado"
                : "Não Assinante"}
            </span>
          </span> */}
          {(active || overdue) && (
            <span
              onClick={() => {
                history.push("/atualizar-senha");
              }}
              className="home__cardBasicButton home__cardRedefinir"
            >
              Redefinir Senha
            </span>
          )}
          {(active || overdue) && (
            <span
              onClick={goToCustomerPortal}
              className="home__cardBasicButton home__cardPortal"
            >
              {loading ? "Aguarde..." : "Portal do Cliente"}
            </span>
          )}
        </div>
       
      </div>

      <span className="home_title">Ainda não é Assinante?</span>
      <span className="home_subtitle">Escolha um dos planos abaixo e assine agora.</span>

      <div className="home__cardsContainer">
        <Card
          cycle="Mensal"
          price="39,"
          cents="90"
          isLoading={isLoading}
          priceMask={priceMask}
          priceId="price_1Lg7x5DaEHvZEwLqMP5DcJ7q"
          loadCheckout={() =>
            loadCheckout("https://pay.hotmart.com/K75599788W?off=co95ybex")
          }
          description="cobrado mensalmente"
        />
        <Card
          cycle="Anual"
          price="19,"
          cents="90"
          isLoading={isLoading}
          priceMask={priceMask}
          priceId="price_1Lg7yXDaEHvZEwLqYXjJbRfr"
          loadCheckout={() =>
            loadCheckout("https://pay.hotmart.com/K75599788W?off=1i9zykm4")
          }
          saves="Economiza R$ 240,00"
          description="R$ 238,80 por ano"
          popular
        />
        <Card
          cycle="Semestral"
          price="29,"
          cents="90"
          isLoading={isLoading}
          priceMask={priceMask}
          priceId="price_1Lg7x5DaEHvZEwLqXa1Ips3b"
          loadCheckout={() =>
            loadCheckout("https://pay.hotmart.com/K75599788W?off=7ko25fz4")
          }
          saves="Economiza R$ 120,00"
          description="R$ 179,40 semestrais"
        />
      </div>

      
      <span className="home_title">Já é Assinante?</span>
      <span className="home_subtitle">Faça o download da versão mais recente do rôbo:</span>
      
      <div className="home__cardUserInfo">
        <div className="home__button">
          <a
            href="automatiza-trading-v10.exe"
            className="home__cardBasicButton home__download"
          >
            Download Robô
          </a>
          <span className="home__cardSubtitle home__versaoAtual">
            Versão Atual (BETA):{" "}
            <span className="home__cardDescription">
              automatiza-trading-v10
            </span>
          </span>
        </div>
      </div>

    </div>
  );
}

export default Home;
