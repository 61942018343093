import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAmuEZSGJoPPcN1p32z70QJeupgMFu9bNs",
  authDomain: "automatizatrading.firebaseapp.com",
  projectId: "automatizatrading",
  storageBucket: "automatizatrading.appspot.com",
  messagingSenderId: "745531176957",
  appId: "1:745531176957:web:121b8655a18c9337998b94",
  measurementId: "G-GKHQ3Q4TXY",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();

export const createUser = createUserWithEmailAndPassword;
export const signIn = signInWithEmailAndPassword;
export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
export default db;
