import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './Testimonials.css'

export default class Testimonials extends Component {
  render() {
    return (
      <div className='testimonials'>
      <h1>O QUE FALAM DE NÓS...</h1>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img src="test5.png" />
          <div className="myCarousel">
            <h3>Anderson Rocha</h3>
            <h4>FUNCIONÁRIO PÚBLICO</h4>
            <p>
            Esse gerenciamento fez eu ganhar tempo e preservou o meu emocional!!! 🚀🚀🚀
            </p>
          </div>
        </div>

        <div>
          <img src="test6.jpeg" />
          <div className="myCarousel">
            <h3>Matheus Rocha</h3>
            <h4>Engenheiro Civil</h4>
            <p>
            Esse gerenciamento é fora de série, muito bom, assim como me ajudou, vai ajudar muita gente que entra numa operação sem norte ou simplesmente entra dando gale infinito. Gerenciamento incrível!
            </p>
          </div>
        </div>

        <div>
          <img src="raissa.jpg" />
          <div className="myCarousel">
            <h3>Raíssa de Siqueira</h3>
            <h4>Professora</h4>
            <p>
            Este gerenciamento me trouxe bastante tranquilidade na hora de operar e me fez bater a meta de forma mais otimizada.
            </p>
          </div>
        </div>

        <div>
          <img src="test3.jpeg" />
          <div className="myCarousel">
            <h3>Raphael Pepe</h3>
            <h4>TRADER</h4>
            <p>
            Fico pensando porque não conheci esse modelo de gerenciamento antes, teria me livrado de quebrar muitas contas , mas agora é rumo ao meu primeiro milhão.💵💵💵💵💵
            </p>
          </div>
        </div>

        <div>
          <img src="test1.jpeg" />
          <div className="myCarousel">
            <h3>Larissa Moura</h3>
            <h4>ADVOGADA</h4>
            <p>
            Graças a esse modelo de gerenciamento eu consegui alinhar o meu operacional e obtive ótimos resultados, minha vida mudou!
            </p>
          </div>
        </div>
      </Carousel>
      </div>
    );
  }
}