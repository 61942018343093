import "./BodyButton.css";

function BodyButton({ onClick, semMargin, textButton, className }) {
  return (
    <div onClick={onClick} className="firstButtonFirstSection__container">
      <div className={`firstButtonFirstSection  ${className} ${semMargin}`}>
        <span>{textButton}</span>
      </div>
    </div>
  );
}

export default BodyButton;
