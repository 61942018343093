import './BoxeSections.css';

function BoxeSections({ img, title, text, desktop }) {
    return (
        <>
            {
                desktop ?
                    <div className='boxeSections boxeSections__desktop'>
                        <img className='boxeSections__img' src={img} />
                        <div className='boxeSections__containerText'>
                            <span className='boxeSections__title'>{title}</span>
                            <span className='boxeSections__text'>{text}</span>
                        </div>
                    </div>
                    :
                    <div className='boxeSections'>
                        <img className='boxeSections__img' src={img} />
                        <span className='boxeSections__title'>{title}</span>
                        <span className='boxeSections__text'>{text}</span>
                    </div>
            }
        </>

    );
}

export default BoxeSections;
