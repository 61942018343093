import "./TelaEmailRecuperacao.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function TelaEmailRecuperacao() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess("Email de recuperação de senha enviado com sucesso!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorCode + " " + errorMessage);
      });
  };

  return (
    <div className="telaEmailRecuperacao__container">
      <div className="telaEmailRecuperacao">
        <div className="telaEmailRecuperacao__header">
          <span className="telaEmailRecuperacao__header_title">
            Recuperar Senha
          </span>
          <span className="telaEmailRecuperacao__header_subtitle">
            Preencha o campo abaixo com o email cadastrado para recuperar a
            senha.
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="telaEmailRecuperacao__inputsContainer">
            <input
              className="telaEmailRecuperacao__inputsContainer_input"
              label="Email"
              placeholder="Email"
              value={email}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {success && (
            <span className="telaEmailRecuperacao__success">{success}</span>
          )}
          {error && (
            <span className="telaEmailRecuperacao__error">{error}</span>
          )}

          <span onClick={handleSubmit} className="telaEmailRecuperacao__button">
            Enviar
          </span>
        </form>
      </div>
      <div className="telaEmailRecuperacao__textoLinkBottom">
        <span className="telaEmailRecuperacao__span">
          Quer tentar fazer login novamente?
        </span>
        <Link to="/login">
          <span className="telaEmailRecuperacao__linkBottom"> Clique aqui</span>
        </Link>
      </div>
    </div>
  );
}

export default TelaEmailRecuperacao;
