import "./TelaCadastro.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// import { auth } from "./firebase";
import db, { createUser, auth } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  serverTimestamp,
  updateDoc,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  setDoc,
} from "firebase/firestore";

import { updateProfile } from "firebase/auth";

function TelaCadastro() {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [senha, setSenha] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const usersRef = collection(db, "customers");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (senha === confirmarSenha) {
      createUser(auth, email, senha)
        .then((userCredential) => {
          setDoc(doc(usersRef, email), {
            email: email,
            displayName: email,
            uid: userCredential.user.uid,
            emailVerified: false,
          });
        })
        .then(() => {
          updateProfile(auth.currentUser, { displayName: email })
            .then(() => {
              history.push("/obrigado");
            })
            .catch((error) => {
              setError(error);
            });
        })
        .catch((e) => setError(e));
    } else {
      setError("A 'Senha' e 'Confirmar Senha' devem ser iguais!");
    }
  };

  return (
    <div className="telaCadastro__container">
      <div className="telaCadastro">
        <div className="telaCadastro__header">
          <span className="telaCadastro__header_title">Cadastre-se</span>
          <span className="telaCadastro__header_subtitle">
            Preencha os campos abaixo para se cadastrar.
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="telaCadastro__inputsContainer">
            <input
              className="telaCadastro__inputsContainer_input"
              label="Email"
              placeholder="Email"
              value={email}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="telaCadastro__inputsContainer_input"
              label="Senha"
              placeholder="Senha"
              value={senha}
              type="password"
              onChange={(e) => setSenha(e.target.value)}
            />
            <input
              className="telaCadastro__inputsContainer_input"
              label="Confirmar Senha"
              placeholder="Confirmar Senha"
              value={confirmarSenha}
              type="password"
              onChange={(e) => setConfirmarSenha(e.target.value)}
            />
          </div>
          {success && <span className="telaCadastro__success">{success}</span>}
          {error && <span className="telaCadastro__error">{error}</span>}
          <span className="telaCadastro__button" onClick={handleSubmit}>
            Cadastrar
          </span>
        </form>
        <div className="telaCadastro__textoLinkBottom">
          <span className="telaCadastro__spanBottom">Para fazer login</span>
          <Link to="/login">
            <span className="telaCadastro__linkBottom"> Clique aqui</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TelaCadastro;
