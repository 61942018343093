import "./TelaObrigado.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { auth } from "./firebase";

function TelaObrigado() {
  return (
    <div className="telaObrigado__container">
      <div className="telaObrigado">
        <div className="telaObrigado__header">
          <span className="telaObrigado__header_title">Obrigado!</span>
          <div className="telaObrigado__header_subtitle">
            <span className="mb-10 ">Agora só falta mais um passo:</span>
            <span className="">
              Basta informar à pessoa que te atendeu, para que ela{" "}
              <strong>ative sua conta.</strong>
            </span>
            <span className="mt-10 ">
              Uma vez ativa, você poderá começar a usufruir do nosso painel de
              confirmação de convidados para o seu casamento.
            </span>
            <span className="mt-10">
              <Link to="/login">
                <span className="telaObrigado__link">
                  {">> Ir para Login <<"}
                </span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TelaObrigado;
