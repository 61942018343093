export const videos = [
    {
     _id: '63D6B_Rvu3M',
     videoUrl: 'https://youtu.be/63D6B_Rvu3M'
    },
     {
       _id: 'g7RN-YlOFS4',
       videoUrl: 'https://youtu.be/g7RN-YlOFS4'
      }
  ];

  