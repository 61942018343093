import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import { PropTypes } from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import "./CarouselVideo.css";
import YoutubeEmbed from "./VideoEmbed";

const DUMMY_VIDEOS = [
  {
    _id: "5fd025a181e2c80897c14ae1",
    videoUrl: "https://www.youtube.com/embed/AVn-Yjr7kDc",
  },
];

const useStyles = makeStyles((theme) => ({
  carousel: {
    margin: theme.spacing(2),
  },
}));

const YoutubeSlide = ({ url, isSelected }) => (
  <div className="reactPlayer">
    <ReactPlayer width="100%" height="512px" url={url} playing={isSelected} />
  </div>
);

const CarouselVideo = ({ data, className }) => {
  const classes = useStyles();

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/default.jpg`;

  // const getVideoId = url =>url.substr('https://www.youtube.com/watch?v='.length, url.length);

  const customRenderThumb = () =>
    data.map((video) => {
      return <img key={video._id} src={getVideoThumb(video._id)} />;
    });

  return (
    <div className={className}>
      {/* <Grid item md={6} xs={12}> */}
      <Carousel
        autoPlay={false}
        className={classes.carousel}
        emulateTouch={true}
        showArrows={true}
        showThumbs={true}
        showStatus={false}
        infiniteLoop={true}
        renderItem={customRenderItem}
        renderThumbs={customRenderThumb}
      >
        {data.map((v) => (
          // <YoutubeSlide
          //   url={v.videoUrl}
          //   muted
          //   playing={false}
          //   key={v._id ? v._id : v.id}
          // />
          <YoutubeEmbed embedId={v._id} />
        ))}
      </Carousel>
      {/* </Grid> */}
    </div>
  );
};

YoutubeSlide.propTypes = {
  url: PropTypes.string,
  isSelected: PropTypes.bool,
};

CarouselVideo.propTypes = {
  data: PropTypes.array,
};

CarouselVideo.defaultProps = {
  data: DUMMY_VIDEOS,
};

export default CarouselVideo;
