import "./FirstSectionMobal.css";
import BodyButton from "./BodyButton";

function FirstSectionMobal({
  titleInBold,
  titleWithoutBold,
  text,
  firstButton,
  secondButton,
  signIn,
}) {
  return (
    <div className="firstSectionMobal">
      <div className="firstSectionMobal__textContainer">
        <span className="firstSectionMobal__textTitle">
          {titleInBold}
          <span className="semNegrito">{titleWithoutBold}</span>
        </span>

        <span className="firstSectionMobal__text">{text}</span>
      </div>

      <div className="firstSectionMobal__buttons">
        <BodyButton onClick={signIn} textButton={firstButton} />
        <BodyButton
          onClick={signIn}
          textButton={secondButton}
          className="bodyButton__backgroundWhiteColorBlack"
        />

        <img className="firstSectionMobal__buttonsCorte" src="white.svg" />
      </div>

      <div className="firstSectionMobal__imgContainer">
        <div className="firstSectionMobal__icons">
          <img
            className="firstSectionMobal__imgLampada"
            src="iconLampada.svg"
          />
          <img className="firstSectionMobal__imgAviao" src="iconCode.svg" />
          <img
            className="firstSectionMobal__imgBonequinhos"
            src="iconBonequinhos.svg"
          />
        </div>

        <img
          className="firstSectionMobal__imgMenina"
          src="meninaDedinhoPraCima.png"
        />
      </div>
    </div>
  );
}

export default FirstSectionMobal;
