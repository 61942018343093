import "./TelaRecoverPassword.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function TelaRecoverPassword() {
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState("");
  const [senhaRepedida, setSenhaRepedida] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const search = window.location.search;

  const getUrlParam = (param) => {
    return search
      .split("&")
      .filter((item) => item.split("=")[0] === param)[0]
      ?.split("=")[1];
  };

  const oobCode = getUrlParam("oobCode");
  const apiKey = getUrlParam("apiKey");
  const mode = getUrlParam("?mode");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `https://identitytoolkit.googleapis.com/v1/accounts:${mode}?key=${apiKey}`,
        {
          oobCode: oobCode,
          newPassword: senha,
        }
      )
      .then(() => {
        console.log("Senha redefinida com sucesso!");
        setSuccess("Senha redefinida com sucesso!");
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        console.log("error", e);
      });
  };

  return (
    <div className="telaRecoverPassword__container">
      <div className="telaRecoverPassword">
        <div className="telaRecoverPassword__header">
          <span className="telaRecoverPassword__header_title">
            Alterar Senha
          </span>
          <span className="telaRecoverPassword__header_subtitle">
            Preencha os campos abaixo para alterar sua senha.
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="telaRecoverPassword__inputsContainer">
            <input
              className="telaRecoverPassword__inputsContainer_input"
              label="Digite uma nova senha"
              placeholder="Digite uma nova senha"
              value={senha}
              type="password"
              onChange={(e) => setSenha(e.target.value)}
            />
            <input
              className="telaRecoverPassword__inputsContainer_input"
              label="Digite a senha novamente"
              placeholder="Digite a senha novamente"
              value={senhaRepedida}
              type="password"
              onChange={(e) => {
                setSenhaRepedida(e.target.value);
                if (e.target.value !== senha) {
                  setError("As senhas não coincidem.");
                } else {
                  setError("");
                }
              }}
            />
          </div>
          {success && (
            <span className="telaRecoverPassword__success">{success}</span>
          )}
          {error && <span className="telaRecoverPassword__error">{error}</span>}

          <span onClick={handleSubmit} className="telaRecoverPassword__button">
            Salvar
            {loading && <CircularProgress />}
          </span>
        </form>
      </div>
      <div className="telaRecoverPassword__textoLinkBottom">
        <span className="telaRecoverPassword__span">
          Quer tentar fazer login novamente?
        </span>
        <Link to="/login">
          <span className="telaRecoverPassword__linkBottom"> Clique aqui</span>
        </Link>
      </div>
    </div>
  );
}

export default TelaRecoverPassword;
