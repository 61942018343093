import BodyButton from "./BodyButton";
import "./FirstSectionDesktop.css";

function FirstSectionDesktop({
  titleInBold,
  titleWithoutBold,
  text,
  firstButton,
  secondButton,
  signIn,
}) {
  return (
    <div className="firstSectionDesktop">
      <div className="firstSectionDesktop__top">
        <div className="firstSectionDesktop__topTextContainer">
          <span className="firstSectionDesktop__topTextTitle">
            {titleInBold}
            <span className="semNegrito">{titleWithoutBold}</span>
          </span>

          <span className="firstSectionDesktop__topText">{text}</span>
        </div>
        <div className="firstSectionDesktop__topDivVazia">
          <div className="firstSectionDesktop__imgContainer">
            <div className="firstSectionDesktop__icons">
              <img
                className="firstSectionDesktop__imgLampada"
                src="iconLampada.svg"
              />
              <img
                className="firstSectionDesktop__imgAviao"
                src="iconCode.svg"
              />
              <img
                className="firstSectionDesktop__imgBonequinhos"
                src="iconBonequinhos.svg"
              />
            </div>

            <img
              className="firstSectionDesktop__imgMenina"
              src="meninaDedinhoProLado.webp"
            />
          </div>
        </div>
      </div>

      <div className="firstSectionDesktop__bottom">
        <div className="firstSectionDesktop__buttonsContainer">
          <div className="firstSectionDesktop__buttons">
            <BodyButton
              onClick={signIn}
              textButton={firstButton}
              semMargin="semMargin"
            />
            <BodyButton
              onClick={signIn}
              textButton={secondButton}
              className="bodyButton__backgroundWhiteColorBlack"
              semMargin="semMargin"
            />
          </div>
          <div className="firstSectionDesktop__topDivVazia"></div>
        </div>
        <img className="firstSectionDesktop__bottomCorte" src="white.svg" />
      </div>
    </div>
  );
}

export default FirstSectionDesktop;
