import BodyButton from "./BodyButton";
import BoxeSections from "./BoxeSections";
import "./SecondSectionMobal.css";

function SecondSectionMobal({
  titleWithoutBold,
  titleInBold,
  button,
  infoBoxes,
  signIn,
}) {
  return (
    <div className="secondSectionMobal">
      <span className="secondSectionMobal__title">
        {titleWithoutBold}
        <span className="comNegrito">{titleInBold}</span>
      </span>

      {infoBoxes.map(({ img, title, text }) => (
        <BoxeSections img={img} title={title} text={text} />
      ))}

      <BodyButton
        onClick={signIn}
        textButton={button}
        className="bodyButton__backgroundDarkPink"
      />
    </div>
  );
}

export default SecondSectionMobal;
