import BodyButton from "./BodyButton";
import "./FridaySection.css";
import FridaySectionCard from "./FridaySectionCard";
import texts from "./texts.json";

function FridaySection({ signIn }) {
  const { titleWithoutBold2, titleInBold2, infoCard } = texts.FridaySection;
  const { firstButton } = texts.FirstSection;

  return (
    <div className="fridaySection">
      <span className="fridaySection__title">
        {titleWithoutBold2}
        <span className="fridaySection__comNegrito">{titleInBold2}</span>
      </span>

      <div className="fridaySection__mobile">
        {infoCard.map(({ title, img, text }) => (
          <FridaySectionCard img={img} text={text} title={title} />
        ))}
      </div>

      <div className="fridaySection__desktop">
        {infoCard.map(({ title, img, text }) => (
          <FridaySectionCard img={img} text={text} title={title} />
        ))}
      </div>
      <BodyButton onClick={signIn} textButton={firstButton} />
    </div>
  );
}

export default FridaySection;
