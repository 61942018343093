import "./VideoSection.css";
import CarouselVideo from "./CarouselVideo";
import { videos } from "./videoData";
import texts from "./texts.json";

function VideoSection() {
  const { titleWithoutBold, titleInBold, infoCard } = texts.FridaySection;
  return (
    <div className="videoSection">
      <span className="fridaySection__title">
        {titleWithoutBold}
        <span className="fridaySection__comNegrito">{titleInBold}</span>
      </span>
      <CarouselVideo className="videoSection__carousel" data={videos} />
    </div>
  );
}

export default VideoSection;
