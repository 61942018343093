import React, { useContext, useState, useEffect } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import db, { googleProvider } from "./firebase";
import { ContextSettings } from "./ContextSettings";
import { useHistory } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import Header from "./Header";
import LoginSection from "./LoginSection";
import VideoSection from "./VideoSection";
import "./Site.css";
import SoftwarePhotos from "./SoftwarePhotos";
import Testimonials from "./Testimonials";
import NewPlans from "./NewPlans";
import Footer from "./Footer";
import FirstSection from "./FirstSection";
import FridaySection from "./FridaySection";
import SecondSection from "./SecondSection";

function Site() {
  const { data, setData } = useContext(ContextSettings);
  const [isDefined, setIsDefined] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const checkIfPasswordIsDefined = async (user) => {
    setLoading(true);
    const docRef = doc(db, "users", user.email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setIsDefined(docSnap.data()?.passwordDefined);
      setLoading(false);
    } else {
      // doc.data() will be undefined in this case
      setIsDefined(false);
      setLoading(false);
    }
  };

  const handleSignIn = async () => {
    const auth = getAuth();
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log("token", token);
        // The signed-in user info.
        const user = result.user;

        setData({ ...data, user: user });
        checkIfPasswordIsDefined(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        console.log("email", email);
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("credential", credential);
        // ...
      });
  };

  useEffect(() => {
    if (isDefined === true) {
      history.push("/login");
    }

    if (isDefined === false) {
      history.push("/atualizar-senha");
    }
  }, [isDefined]);

  useEffect(() => {
    if (data?.user) {
      history.push("/home");
    }
  }, []);

  return (
    <div className="site">
      <Header signIn={handleSignIn} loading={loading} />
      <FirstSection signIn={handleSignIn} />
      <SecondSection signIn={handleSignIn} />
      <VideoSection />
      <FridaySection signIn={handleSignIn} />
      <Footer />
    </div>
  );
}

export default Site;
