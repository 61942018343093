import "./FirstSection.css";
import FirstSectionMobal from "./FirstSectionMobal";
import FirstSectionDesktop from "./FirstSectionDesktop";
import texts from "./texts.json";

function FirstSection({ signIn }) {
  const { titleInBold, titleWithoutBold, text, firstButton, secondButton } =
    texts.FirstSection;

  return (
    <div className="firstSection">
      <FirstSectionMobal
        titleInBold={titleInBold}
        titleWithoutBold={titleWithoutBold}
        text={text}
        firstButton={firstButton}
        secondButton={secondButton}
        signIn={signIn}
      />
      <FirstSectionDesktop
        titleInBold={titleInBold}
        titleWithoutBold={titleWithoutBold}
        text={text}
        firstButton={firstButton}
        secondButton={secondButton}
        signIn={signIn}
      />
    </div>
  );
}

export default FirstSection;
