import "./TelaLogin.css";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import db, { signIn, auth } from "./firebase";
import { getDoc, doc } from "firebase/firestore";
import { ContextSettings } from "./ContextSettings";
import { useEffect } from "react";

function TelaLogin() {
  const { data, setData } = useContext(ContextSettings);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userRef = doc(db, "users", email);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    if (userSnap.exists && userData?.email) {
      signIn(auth, userData.email, senha)
        .then((userCredential) => {
          const { email, displayName, uid, emailVerified } =
            userCredential.user;
          setData({
            ...data,
            user: { email, displayName, uid, emailVerified },
          });
        })
        .then(() => {
          history.push("/home");
        })
        .catch((e) => setError("Senha inválida."));
    } else {
      setError(`Email "${email}" inválido.`);
    }
  };

  useEffect(() => {
    if (data.user) {
      history.push("/home");
    }
  }, [data]);

  return (
    <div className="telaLogin__container">
      <div className="telaLogin">
        <div className="telaLogin__header">
          <span className="telaLogin__header_title">Acesse sua conta</span>
          <span className="telaLogin__header_subtitle">
            Preencha os campos abaixo para fazer login em sua conta.
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="telaLogin__inputsContainer">
            <input
              className="telaLogin__inputsContainer_input"
              label="Email"
              placeholder="Email"
              value={email}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="telaLogin__inputsContainer_input"
              label="Senha"
              placeholder="Senha"
              value={senha}
              type="password"
              onChange={(e) => setSenha(e.target.value)}
            />
            {error && <span className="telaLogin__error">{error}</span>}
          </div>

          <div className="telaLogin__linkContainer">
            <Link to="/email-de-recuperacao">
              <div className="telaLogin__linkContainer_link">
                <span>Esqueceu sua senha?</span>
              </div>
            </Link>
          </div>

          <div className="telaLogin__button" onClick={handleSubmit}>
            Entrar
          </div>
        </form>

        <div className="telaLogin__textoLinkBottom">
          <span>Ainda não tem uma conta?</span>{" "}
          <Link to="/">
            <span className="telaLogin__linkBottom"> Cadastre-se aqui</span>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TelaLogin;
