import "./Footer.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const footerData = [
  {
    icon: <LocationOnIcon />,
    description: "Campos dos Goytacazes - RJ",
    map: true,
  },
];

function Footer() {
  return (
    <div className="footer">
      <div className="footer__left">
        {footerData.map((item) => {
          return (
            <div className="footer__row">
              {item.map ? (
                <a
                  href="https://www.google.com/maps/place/Campos+-+Campos+dos+Goytacazes,+State+of+Rio+de+Janeiro/@-21.7694388,-41.3260406,15.06z/data=!4m5!3m4!1s0xbdd5d1be81b545:0xee97b136e7168ee1!8m2!3d-21.7614561!4d-41.3167841"
                  target="_blank"
                >
                  <div className="footer__rowIcon">{item.icon}</div>
                </a>
              ) : (
                <div className="footer__rowIcon">{item.icon}</div>
              )}
              <div className="footer__rowDescription">{item.description}</div>
            </div>
          );
        })}
        <a
          className="footer__leftImg"
          href="https://www.google.com/maps/place/Campos+-+Campos+dos+Goytacazes,+State+of+Rio+de+Janeiro/@-21.7694388,-41.3260406,15.06z/data=!4m5!3m4!1s0xbdd5d1be81b545:0xee97b136e7168ee1!8m2!3d-21.7614561!4d-41.3167841"
          target="_blank"
        >
          <img src="https://www.google.com/maps/vt/data=tx9j7-hsFF0U-CFi-YzP7RD0YCZd_q5K2K6m1dalDZZXoHxfZa5zLtSAE63Bpv6sZHSNjSlKZVk2-4-YXTtcifYqRJ6ZjpJxcTD8g4clvhVOwFg94UACoIAocUO3MVKEDXzbA3PcNUjX89pfdrBCDIzKun9dzAbspP6jKvZ-AG1Z2DEgOSUvXtYxigul4k4rWWYPX0PFlWiQb__vIAzKBLqFKJ7yLoG9dlZy39jfzif3Ih0vbGDINA" />
        </a>
      </div>

      <div className="footer__right">
        <h1>Sobre Nós</h1>
        <p>
          Automatiza Trading é um software que utiliza algoritmos matemáticos
          para calcular automaticamente qual o valor de cada entrada que você
          deverá fazer, baseando-se nos seus erros e acertos para obter o lucro
          que você deseja, de forma simples e eficiente. Obtenha lucro sempre
          que acertar apenas 2 operações, consecutivas ou não.
        </p>
        <div className="footer__rightSocial">
          <a href="#" target="_blank">
            <YouTubeIcon />
          </a>
          <a href="#" target="_blank">
            <InstagramIcon />
          </a>
          <a href="#" target="_blank">
            <WhatsAppIcon />
          </a>
          +55 22 99999-9999
        </div>
        <div className="footer_bottom">
          {/* <span onClick={() => openTab("/termos-de-servico")}> */}
          <span>Termos de Uso</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
