import React from "react";
import "./LoginSection.css";

function LoginSection({ signIn, loading }) {
  return (
    <div className="login">
      {/* <img className='login__img'/> */}
      <div className="login__background" />

      <div className="login__topContainer">
        <div className="login__headerContainer">
          <h1>AUTOMATIZA TRADING</h1>
          <div className="login__headerBorder"></div>
        </div>

        <p>
          Não deixe o seu emocional te impedir de ser lucrativo em Opções
          Binárias.
        </p>

        <div onClick={signIn} className="login__registerButton">
          {loading ? "CARREGANDO..." : "ENTRAR"}
        </div>
        <span>Entre com uma conta do Google</span>
      </div>
    </div>
  );
}

export default LoginSection;
