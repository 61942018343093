import "./SecondSectionDesktop.css";
import BodyButton from "./BodyButton";
import BoxeSections from "./BoxeSections";

function SecondSectionDesktop({
  titleWithoutBold,
  titleInBold,
  button,
  infoBoxes,
  signIn,
}) {
  return (
    <div className="secondSectionDesktop">
      <span className="secondSectionDesktop__title">
        {titleWithoutBold}
        <span className="comNegrito">{titleInBold}</span>
      </span>
      <div className="secondSectionDesktop__containerCarinhaBoxe">
        <div className="secondSectionDesktop__divVazia">
          <img
            className="secondSectionDesktop__imgCara"
            src="AutomatizaTrading.png"
          />
        </div>

        <div className="secondSectionDesktop__containerCarinhaBoxe_boxes">
          {infoBoxes.map(({ img, title, text }) => (
            <BoxeSections img={img} title={title} text={text} desktop />
          ))}
        </div>
      </div>

      <BodyButton
        onClick={signIn}
        textButton={button}
        className="bodyButton__backgroundDarkPink buttonBigger"
      />
    </div>
  );
}

export default SecondSectionDesktop;
