import "./SecondSection.css";
import SecondSectionDesktop from "./SecondSectionDesktop";
import SecondSectionMobal from "./SecondSectionMobal";
import texts from "./texts.json";

function SecondSection({ signIn }) {
  const { titleWithoutBold, titleInBold, button, infoBoxes } =
    texts.SecondSection;

  return (
    <div className="secondSection">
      <SecondSectionMobal
        titleWithoutBold={titleWithoutBold}
        titleInBold={titleInBold}
        button={button}
        infoBoxes={infoBoxes}
        signIn={signIn}
      />
      <SecondSectionDesktop
        titleWithoutBold={titleWithoutBold}
        titleInBold={titleInBold}
        button={button}
        infoBoxes={infoBoxes}
        signIn={signIn}
      />
    </div>
  );
}

export default SecondSection;
